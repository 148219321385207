.MuiTable-root tr td:last-child div {
	justify-content: flex-end;
}

.MuiTableBody-root tr:first-child td:last-child {
	background: white;
	padding: 4px 16px;
	position: sticky;
	z-index: 99;
	top: 25px;
}

.MuiTableBody-root tr:first-child td .MuiInputBase-input {
	font-size: 0.8125rem;
}

.MuiTableRow-hover:hover {
	background-color: #ffffbf !important;
}

.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
	font-size: 0.8125rem;
}

details hr {
	display: none !important;
}
